import DownloadList from '../../../../components/DownloadList'
import TopBar from '../../../../components/TopBar'
import './styles.css'


export default function ManualsPage(){
    const manuals = [
        {title: 'Manual do App', link: 'http://3.92.136.22:8000/manual/'},
    ]

    return (
        <div className='MainContainer'>
            <TopBar/>  

            <div style={{padding: '1rem'}}/>

            <DownloadList
                data={manuals}
                icon={'pdf'}
                title='Documentação - Manuais'
            />
        </div>
    )
}