import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import './styles.css';

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="intro">Número de Testes: {payload[0].value}</p>
            </div>
        );
    }

    return null;
};

export default function CustomLinePlot({data=[], xAxisKey="x", yAxisKey="y", stroke="#00ff58"}: any) {
    const marginLeft: number = window.window.innerWidth * -0.02

    const xData = data.x
    const yData = data.y
    data = []

    for(let i=0; i<xData.length; i++)
        data.push({x: xData[i].split(' ')[0], y: yData[i]})


    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={data}
                margin={{ top: 0, right: 0, left: marginLeft, bottom: 0 }}
            >
                <XAxis dataKey={xAxisKey} tick={{ fontSize: 'calc(5px + .5vh)', }} />
                <YAxis dataKey={yAxisKey} tick={{ fontSize: 'calc(5px + .5vh)' }}/>
                <Tooltip content={<CustomTooltip />} />
                <Line type="monotone" dataKey={yAxisKey} stroke={stroke} dot={false}/>
            </LineChart>
        </ResponsiveContainer>
    );
}