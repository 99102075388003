import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faCloudArrowDown, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

import './styles.css'

import CustomModal from '../../../../components/Modal'
import ScrollView from '../../../../components/ScrollView'
import CustomDropBox from '../../../../components/CustomDropBox'

type SoftwareModalProps = {
    isOpen: boolean;
    params: any;
    onClose: () => void;
    setParams: (newParam: any) => void;
    onDownloadConfig: () => Promise<void>;
    onDownloadConfigFile: () => Promise<void>;
}

export function SoftwareModal({ isOpen, onClose, params, setParams, onDownloadConfig, onDownloadConfigFile }: SoftwareModalProps) {
    const [selectedOpMode, setSelectedOpMode] = useState({})
    const [selectedCommType, setSelectedCommType] = useState({})
    const [selectedEnableRandom, setSelectedEnableRandom] = useState({})
    const [selectedHasCamera, setSelectedHasCamera] = useState({})
    const [selectedCameraTime, setSelectedCameraTime] = useState({})
    const [selectedTravelTime, setSelectedTravelTime] = useState({})
    const [selectedNumTests, setSelectedNumTests] = useState({})

    const [isConfigDownloadBusy, setIsConfigDownloadBusy] = useState(false)
    const [isConfigFileDownloadBusy, setIsConfigFileDownloadBusy] = useState(false)

    const opModeOpts = [
        { label: 'Sensor', value: 0 },
        { label: 'Autônomo', value: 1 },
    ]
    const commTypeOpts = [
        { label: 'Padrão', value: 0 },
        { label: 'Bluetooth', value: 1 }, 
        { label: 'Serial', value: 2 }, 
    ]
    const yesNoOpts = [
        { label: 'Não', value: 0 },
        { label: 'Sim', value: 1 }, 
    ]
    const cameraTimeOpts = [
        { label: '5 s', value: 5 }, 
        { label: '10 s', value: 10 }, 
        { label: '30 s', value: 30 }, 
        { label: '60 s', value: 60 }, 
        { label: '120 s', value: 120 }
    ]
    const travelTimeOpts = [
        { label: '0.16 h', value: 0.16 }, 
        { label: '1 h', value: 1 }, 
        { label: '2 h', value: 2 }, 
        { label: '3 h', value: 3 }, 
        { label: '5 h', value: 5 }, 
        { label: '10 h', value: 10 }
    ]
    const numTestOpts = [
        { label: '1 Teste', value: 1 }, 
        { label: '2 Testes', value: 2 },  
        { label: '3 Testes', value: 3 },  
        { label: '4 Testes', value: 4 },  
        { label: '5 Testes', value: 5 }, 
    ]

    useEffect(() => { 
        if (params.operation_mode !== undefined) {
            const newValue = opModeOpts.find((item) => item.value === params.operation_mode) ?? {}
            setSelectedOpMode(newValue)
        }

        if (params.comm_type !== undefined) {
            const newValue = commTypeOpts.find((item) => item.value === params.comm_type) ?? {}
            setSelectedCommType(newValue)
        }

        if (params.enable_random !== undefined) {
            const newValue = yesNoOpts.find((item) => item.value === params.enable_random) ?? {}
            setSelectedEnableRandom(newValue)
        }

        if (params.camera !== undefined) {
            const newValue = yesNoOpts.find((item) => item.value === params.camera) ?? {}
            setSelectedHasCamera(newValue)
        }

        if (params.camera_time !== undefined) {
            const newValue = cameraTimeOpts.find((item) => item.value === params.camera_time) ?? {}
            setSelectedCameraTime(newValue)
        }

        if (params.time_of_travel !== undefined) {
            const newValue = travelTimeOpts.find((item) => item.value === params.time_of_travel) ?? {}
            setSelectedTravelTime(newValue)
        }

        if (params.number_of_tests !== undefined) {
            const newValue = numTestOpts.find((item) => item.value === params.number_of_tests) ?? {}
            setSelectedNumTests(newValue)
        }
    }, [params])

    return (
        <CustomModal className='SoftwareModal' modalOpen={isOpen} interfere={false}>
            <div>
                <div id='software-modal-header-label'>
                    <div style={{ display: 'flex', fontSize: '1rem', color: 'white' }}>
                        Configuração de Parâmetros
                    </div>

                    <div
                        className={'modal-sync-button' + (isConfigDownloadBusy ? ' sync-busy' : '')}
                        onClick={async () => {
                            if (isConfigDownloadBusy)
                                return
                            
                            setIsConfigDownloadBusy(true)
                            await onDownloadConfig()
                            setIsConfigDownloadBusy(false)
                        }}
                        title='Baixar configuração padrão'
                    >
                        <FontAwesomeIcon
                            icon={faCloudArrowDown}
                            size='xl'
                            style={{ opacity: .6 }}
                        />
                    </div>

                    <div
                        className={'modal-sync-button' + (isConfigFileDownloadBusy ? ' sync-busy' : '')}
                        onClick={async () => {
                            if (isConfigFileDownloadBusy)
                                return

                            setIsConfigFileDownloadBusy(true)
                            await onDownloadConfigFile()
                            setIsConfigFileDownloadBusy(false)
                        }}
                        title='Baixar arquivo de configuração padrão'
                    >
                        <FontAwesomeIcon
                            icon={faFileArrowDown}
                            size='xl'
                            style={{ opacity: .6 }}
                        />
                    </div>
                </div>
                
                <div onClick={onClose}>
                    <FontAwesomeIcon icon={faX} cursor='pointer' color='gray' />
                </div>
            </div>

            <div style={{ padding: '1rem' }} />

            <ScrollView className='params-config-container'>
                <div className='params-config-item'>
                    <span>
                        Modo de Operação
                    </span>

                    <CustomDropBox
                        value={selectedOpMode}
                        setValue={(newValue: any) => {
                            setSelectedOpMode(newValue)
                            setParams({ ...params, operation_mode: newValue.value })
                        }}
                        options={opModeOpts}
                        placeholder='Selecione uma opção'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div className='params-config-item'>
                    <span>
                        Modo de Comunicação
                    </span>

                    <CustomDropBox
                        value={selectedCommType}
                        setValue={(newValue: any) => {
                            setSelectedCommType(newValue)
                            setParams({ ...params, comm_type: newValue.value })
                        }}
                        options={commTypeOpts}
                        placeholder='Selecione uma opção'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div className='params-config-item'>
                    <span>
                        Modo Randômico
                    </span>

                    <CustomDropBox
                        value={selectedEnableRandom}
                        setValue={(newValue: any) => {
                            setSelectedEnableRandom(newValue)
                            setParams({ ...params, enable_random: newValue.value })
                        }}
                        options={yesNoOpts}
                        placeholder='Selecione uma opção'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div className='params-config-item'>
                    <span>
                        Câmera no Veículo
                    </span>

                    <CustomDropBox
                        value={selectedHasCamera}
                        setValue={(newValue: any) => {
                            setSelectedHasCamera(newValue)
                            setParams({ ...params, camera: newValue.value })
                        }}
                        options={yesNoOpts}
                        placeholder='Selecione uma opção'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div className='params-config-item'>
                    <span>
                        Tempo de Aguardando Câmera
                    </span>

                    <CustomDropBox
                        value={selectedCameraTime}
                        setValue={(newValue: any) => {
                            setSelectedCameraTime(newValue)
                            setParams({ ...params, camera_time: newValue.value })
                        }}
                        options={cameraTimeOpts}
                        placeholder='Selecione uma opção'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div className='params-config-item'>
                    <span>
                        Tempo de Viagem
                    </span>

                    <CustomDropBox
                        value={selectedTravelTime}
                        setValue={(newValue: any) => {
                            setSelectedTravelTime(newValue)
                            setParams({ ...params, time_of_travel: newValue.value })
                        }}
                        options={travelTimeOpts}
                        placeholder='Selecione uma opção'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div className='params-config-item'>
                    <span>
                        Número de Testes
                    </span>

                    <CustomDropBox
                        value={selectedNumTests}
                        setValue={(newValue: any) => {
                            setSelectedNumTests(newValue)
                            setParams({ ...params, number_of_tests: newValue.value })
                        }}
                        options={numTestOpts}
                        placeholder='Selecione uma opção'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div id='software-modal-footer'>
                    <div className='configureButton' onClick={onClose}>
                        Salvar
                    </div>
                </div>
            </ScrollView>
        </CustomModal>
    )
}