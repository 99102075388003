import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopBar from "../../../../components/TopBar";
import { faMobileAlt, faSearch, faTruck } from "@fortawesome/free-solid-svg-icons";

import './styles.css';
import { getStoredJson, sleep, useInitialCheck } from "../../../../utils";
import { getDataBaseCondition, getDatabaseRows, getServerGraphs } from "../../../../utils/database";
import CustomHalfDonutChart from "../../../../components/Graphs/CustomHalfDonutChart";
import CustomLinePlot from "../../../../components/Graphs/CustomLineChart";
import CustomPieChart from "../../../../components/Graphs/CustomPieChart";
import RenderMap from "../../../../components/Graphs/Map";
import { DateSelector, formatTimestamp } from "../../../../components/DatePicker";
import { Table, TableCell, TableRow } from '../../../../components/Table';
import LoadingDiv from "../../../../components/LoadingDiv";
import CustomDropBox from "../../../../components/CustomDropBox";
import ScrollView from "../../../../components/ScrollView";
import { standardData, emptyData } from "../sample";

function formatRawEvent(key: string){
    const labels = {
        '$ETEV15!': 'Muitos Adiamentos',
        '$ETEV30': 'Álcool Detectado',
        '$ETEV21': 'Sensor Vencido',
        '$ETEV27': 'Temperatura Alta',
        '$ETEV33': 'Motorista não autorizado',
    }

    for(let [event, label] of Object.entries(labels))
        if(key == event)
            return label

    return key
}

export default function ControlPage(){
    const [plates, setPlates] = useState<any>([])
    const [selectedPlate, setSelectedPlate] = useState<any>({})
    const [update, setUpdate] = useState<boolean>(false)
    const [inicialDate, setInitialDate] = useState<string>('')
    const [finalDate, setFinalDate] = useState<string>('')
    const [webData, setWebData] = useState<any>(standardData)
    const [selectedCompany, setSelectedCompany] = useState<any>({})
    const [timeTravel, setTimeTravel] = useState<any>({greater: true, lesser: false})
    const [vehicleState, setVehicleState] = useState<any>({stopped: false, travel: true})
    const [companies, setCompanies] = useState<any>([])
    const navigate = useNavigate()
    useInitialCheck(importData)
    useInitialCheck(updateDash)

    async function updateDash() {
        setUpdate(true)
        await importGraphs()
        await sleep(500)
        setUpdate(false)
    }

    async function importData(){
        await importCompanies()
        await importPlates()
    }

    async function importCompanies(){
        const response = getStoredJson('companies', [])
        setCompanies(response)
    }

    async function importPlates(){
        const response = await getDatabaseRows('etilometers')
        const target   = response.map((item: any) => ({label: item.vehicle_plate}))
        setPlates(target)
    }

    async function importGraphs(){
        let data: any = {
            target_plates: []
        }
        if(selectedCompany.value){
            const target = await getDataBaseCondition('etilometers', 'company', selectedCompany.id)
            data.target_plates = target.map((item: any) => item.vehicle_plate)
        }
        
        if(selectedPlate.label)
            data.target_plates = [selectedPlate.label]

        if(inicialDate.length > 0)
            data.initial_date = inicialDate

        if(finalDate.length > 0)
            data.final_date = finalDate

        data.time_travel_greater   = timeTravel.greater
        data.time_travel_lesser    = timeTravel.lesser
        data.vehicle_state_stopped = vehicleState.stopped
        data.vehicle_state_travel  = vehicleState.travel
        const response = await getServerGraphs(data)

        if(!response || response.status == 'error')
            return setWebData(emptyData)

        setWebData(response.webData)
    }

    function renderLeftContainer() {
        return (
            <ScrollView className='ControlContainer' style={{ width: '28%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' }}>
                <div style={{ width: '100%', height: '15%', display: 'flex', justifyContent: 'space-between' }}>
                    <div className="carIconContainer" style={{ backgroundColor: 'gray' }}>
                        <FontAwesomeIcon icon={faTruck} className="carIcon" size='sm' />
                    </div>

                    <div className="carIconContainer" onClick={() => navigate('/Etilometro/Sensor')}>
                        <FontAwesomeIcon icon={faMobileAlt} className="carIcon" />
                    </div>
                </div>

                <div style={{ padding: '1rem' }} />

                <div style={{ width: '100%' }}>
                    <CustomDropBox
                        options={plates}
                        value={selectedPlate}
                        setValue={setSelectedPlate}
                        placeholder='Placa do Veículo'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ width: '100%' }}>
                    <CustomDropBox
                        options={companies}
                        value={selectedCompany}
                        setValue={setSelectedCompany}
                        placeholder='Empresa'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ width: '100%' }}>
                    <DateSelector
                        value={inicialDate}
                        onChange={setInitialDate}
                        placeholder='Data Inicial'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ width: '100%' }}>
                    <DateSelector
                        value={finalDate}
                        onChange={setFinalDate}
                        placeholder='Data Final'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ color: 'white', alignSelf: 'flex-start', fontSize: 'calc(5px + .5rem)', fontWeight: 600 }}>
                    Tempo de Viagem
                </div>

                <div style={{ padding: '.3rem' }} />

                <div
                    style={{ display: 'flex', width: '100%', cursor: 'pointer' }}
                    onClick={() => setTimeTravel({ ...timeTravel, greater: !timeTravel.greater })}
                >
                    <div
                        className="buttonTravel"
                        style={{ backgroundColor: timeTravel.greater ? 'white' : 'gray' }}
                    >
                        Maior que 15 min
                    </div>
                </div>

                <div style={{ padding: '.3rem' }} />

                <div
                    style={{ display: 'flex', width: '100%', cursor: 'pointer' }}
                    onClick={() => setTimeTravel({ ...timeTravel, lesser: !timeTravel.lesser })}
                >
                    <div
                        className="buttonTravel"
                        style={{ backgroundColor: timeTravel.lesser ? 'white' : 'gray' }}
                    >
                        Menor que 15 min
                    </div>
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ color: 'white', alignSelf: 'flex-start', fontSize: 'calc(5px + .5rem)', fontWeight: 600 }}>
                    Parado vs Em viagem
                </div>

                <div style={{ padding: '.3rem' }} />

                <div
                    style={{ display: 'flex', width: '100%', cursor: 'pointer' }}
                    onClick={() => setVehicleState({ ...vehicleState, stopped: !vehicleState.stopped })}
                >
                    <div
                        className="buttonTravel"
                        style={{ backgroundColor: vehicleState.stopped ? 'white' : 'gray' }}
                    >
                        Veículo parado
                    </div>
                </div>

                <div style={{ padding: '.3rem' }} />

                <div
                    style={{ display: 'flex', width: '100%', cursor: 'pointer' }}
                    onClick={() => setVehicleState({ ...vehicleState, travel: !vehicleState.travel })}
                >
                    <div
                        className="buttonTravel"
                        style={{ backgroundColor: vehicleState.travel ? 'white' : 'gray' }}
                    >
                        Veículo em viagem
                    </div>
                </div>

                <div style={{ padding: '1rem' }} />

                <button 
                    className='buttonInfoContainer' 
                    onClick={async () => await updateDash()} 
                    style={{width: '50%', opacity: update ? 0.6 : 1, cursor: update ? 'not-allowed' : 'pointer'}}
                    disabled={update}
                >
                    <FontAwesomeIcon icon={faSearch} style={{ paddingRight: '7%' }} />
                    <p style={{ fontSize: 'calc(5px + .5vw)' }}>Consultar</p>
                </button>
            </ScrollView>
        )
    }

    function renderPieLabel(label: string, color: string) {
        return (
            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: color }} />

                <div style={{ width: '85%', fontSize: 'calc(5px + .5vh)', color: 'white' }}>
                    {label}
                </div>
            </div>
        )
    }

    function formatEvents(events: any){
        let data = []
        
        for(let [key, value] of Object.entries(events)){
            const target: any = value
            
            for(let event of target){
                const plate = event.plate
                const timestamp = event.timestamp

                data.push(
                    <TableRow>
                        <TableCell>{plate}</TableCell>
                        <TableCell>{formatRawEvent(key)}</TableCell>
                        <TableCell>{formatTimestamp(timestamp)}</TableCell>
                    </TableRow>
                )
            }
        }

        return data
    }

    function renderRightContainer() {
        return (
            <div className='ControlContainer' style={{ width: '60%', justifyContent: 'space-between', padding: 0 }}>
                <div style={{ display: 'flex', width: '100%', height: '15%', justifyContent: 'space-between' }}>
                    <LoadingDiv loading={update} className='controlItem'>
                        <span>
                            Total de Viagens
                        </span>
                        <br /><br />
                        <span>
                            {webData?.total_travels}
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem'>
                        <span>
                            Duração Média
                        </span>
                        <br /><br />
                        <span>
                            {Math.floor(webData.average_travel_time)}h e {Math.floor((webData.average_travel_time % 1) * 60)} min.
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem'>
                        <span>
                            Distância Total
                        </span>
                        <br /><br />
                        <span>
                            {webData.total_distance} Km
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem'>
                        <span>
                            Último Sincronismo
                        </span>
                        <br />
                        <span>
                            {webData.last_sync ? formatTimestamp(webData.last_sync) : ''}
                        </span>
                    </LoadingDiv>
                </div>

                <div style={{ display: 'flex', width: '100%', height: '30%', justifyContent: 'space-between' }}>
                    <LoadingDiv loading={update} className='controlItem' style={{ width: '31.5%', }}>
                        <div style={{ width: '100%', fontSize: 'calc(5px + .6vw)' }}>
                            Quantidade de Testes Realizados
                        </div>

                        <div style={{ padding: '12px' }} />

                        <div style={{ display: 'flex', width: '100%', flex: 1 }}>
                            <CustomLinePlot data={webData.tests_graph} />
                        </div>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '25%' }}>
                        <div style={{ color: 'white' }}>
                            Total de Testes Randômicos
                        </div>

                        <div style={{ display: 'flex', width: '100%', flex: 1, alignItems: 'center' }}>
                            <div className="graphContainer">
                                <CustomHalfDonutChart
                                    data={[
                                        { name: 'testes', value: webData.random_tests_graph.val },
                                        { name: 'total', value: webData.random_tests_graph.max - webData.random_tests_graph.val },
                                    ]}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: '80%', justifyContent: 'space-between' }}>
                            <div>{webData.random_tests_graph.min}</div>
                            <div>{webData.random_tests_graph.val}</div>
                            <div>{webData.random_tests_graph.max}</div>
                        </div>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '38%', }}>
                        <div style={{ color: 'white' }}>
                            Resultados de Testes
                        </div>

                        <div style={{ padding: '2px' }} />
                                    
                        <CustomPieChart data={[
                            { 
                                name: 'Bloqueado (álcool)',
                                value: webData.tests_results_graph.blocked,
                                color: '#ff6961'
                            },
                            { 
                                name: 'Liberado', 
                                value: webData.tests_results_graph.allowed, 
                                color: '#00ff58' 
                            },
                            { 
                                name: 'Sem Sopro', 
                                value: webData.tests_results_graph.no_blow, 
                                color: '#004819'
                            },
                            { 
                                name: 'Adiado', 
                                value: webData.tests_results_graph.postponed, 
                                color: 'blue'
                            },
                        ]}
                        />

                        <div style={{ width: '100%', color: 'gray', justifyContent: 'space-between', display: 'flex' }}>
                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Bloqueado (álcool)', '#ff6961')}
                            </div>

                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Liberado', '#00ff58')}
                            </div>

                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Sem Sopro', '#004819')}
                            </div>

                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Adiado', 'blue')}
                            </div>
                        </div>

                        <div style={{ padding: '2px' }} />
                    </LoadingDiv>
                </div>

                <div style={{ display: 'flex', width: '100%', height: '45%', justifyContent: 'space-between' }}>
                    <LoadingDiv loading={update} style={{ display: 'flex', flexDirection: 'column', width: '49%', height: '100%', justifyContent: 'space-between' }}>
                        <div
                            className='controlItem'
                            style={{ width: '100%', height: '25%', justifyContent: 'center' }}
                        >
                            <div style={{ color: 'white' }}>
                                Percentual de Randômicos
                            </div>

                            <div style={{ padding: '2.5px' }} />

                            <div style={{ color: 'white', fontSize: 'calc(6px + .6rem)' }}>
                                {webData.total_randomic_percentage}%
                            </div>
                        </div>

                        <div className='controlItem' style={{ width: '100%', height: '70%' }}>
                            <div style={{ color: 'white' }}>
                                Últimos Eventos de Alerta
                            </div>

                            <div style={{ padding: '5px' }} />

                            <Table>
                                <TableRow>
                                    <TableCell>Placa</TableCell>
                                    <TableCell>Evento</TableCell>
                                    <TableCell>Data</TableCell>
                                </TableRow>

                                {formatEvents(webData.eventsTable)}
                            </Table>
                        </div>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '48%' }}>
                        <div style={{ color: 'white' }}>
                            Etilômetros instalados
                        </div>

                        <div style={{ padding: '5px' }} />

                        <div style={{ width: '100%', flex: 1 }}>
                            <RenderMap data={webData.installed_devices_graph} />
                        </div>
                    </LoadingDiv>
                </div>
            </div>
        )
    }

    return (
        <div className="MainContainer">
            <TopBar />
            <div style={{ padding: '1rem' }} />

            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                {renderLeftContainer()}

                <div style={{ padding: '1.5%' }} />

                {renderRightContainer()}
            </div>

        </div>
    )
}