import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopBar from "../../../../components/TopBar";
import { faMobileAlt, faSearch, faTruck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import './styles.css';
import { useState } from "react";
import { getStoredJson, saveJsonData, sleep, useInitialCheck } from "../../../../utils";
import { getDataBaseCondition, getDatabaseRows, getServerGraphs } from "../../../../utils/database";
import CustomPieChart from "../../../../components/Graphs/CustomPieChart";
import RenderMap from "../../../../components/Graphs/Map";
import { formatTimestamp } from "../../../../components/DatePicker";
import { Table, TableCell, TableRow } from '../../../../components/Table';
import LoadingDiv from "../../../../components/LoadingDiv";
import CustomDropBox from "../../../../components/CustomDropBox";
import ScrollView from "../../../../components/ScrollView";
import { standardData, emptyData } from "../sample";


export default function SensorPage() {
    const [plates, setPlates] = useState<any>([])
    const [selectedPlate, setSelectedPlate] = useState<any>({})
    const [update, setUpdate] = useState<boolean>(false)
    const [webData, setWebData] = useState<any>(standardData)
    const [selectedCompany, setSelectedCompany] = useState<any>({})
    const [deprecatedDevices, setDeprecatedDevices] = useState<any>([])
    const [almostDeprecatedDevices, setAlmostDeprecatedDevices] = useState<any>([])
    const [deprecatedSelected, setDeprecatedSelected] = useState<boolean>(false)
    const [almostDeprecatedSelected, setAlmostDeprecatedSelected] = useState<boolean>(false)
    const [targetSensors, setTargetSensors] = useState<any>([])
    const [companies, setCompanies] = useState([])

    const navigate = useNavigate()
    useInitialCheck(importData)
    useInitialCheck(updateGraphs)

    async function updateGraphs(){
        setUpdate(true)

        let data: any = {
            target_plates: []
        }

        const devices = await getDatabaseRows('etilometers')
        const target  = getTargetSensors(devices)
        setTargetSensors(target)
        
        if(target.length > 0)
            data.target_plates = target.map((item: any) => item.vehicle_plate)
        
        if(selectedPlate.label)
            data.target_plates = [selectedPlate.label]

        const response = await getServerGraphs(data)
        
        if(!response || response.status == 'error')
            return setWebData(emptyData)

        setWebData(response.webData)

        setUpdate(false)
    }

    async function importData(){
        await importCompanies()
        await importPlates()
    }

    async function importCompanies(){
        const response = getStoredJson('companies', [])
        setCompanies(response)
    }

    async function importPlates(){
        const response = await getDatabaseRows('etilometers')
        const target   = response.map((item: any) => ({label: item.vehicle_plate}))
        setPlates(target)
    }
        
    function getTargetSensors(devices: any){
        let target = [...devices]

        if (selectedCompany.label)
            target = target.filter((item: any) => item.company == selectedCompany.label)

        if (selectedPlate.label)
            target = target.filter((item: any) => item.vehicle_plate == selectedPlate.label)

        if (deprecatedSelected && !almostDeprecatedSelected)
            target = target.filter((item: any) => isDeprecated(item))

        if (almostDeprecatedSelected && !deprecatedSelected)
            target = target.filter((item: any) => isAlmostDeprecated(item))

        if (almostDeprecatedSelected && deprecatedSelected)
            target = target.filter((item: any) => isAlmostDeprecated(item) || isDeprecated(item))

        return target
    }

    function getDays(milliseconds: number) {
        const seconds = milliseconds / 1000;
        const days = seconds / (60 * 60 * 24);
        return Math.floor(days);
    }

    function isDeprecated(device: any) {
        let installation_date: any = new Date(device.installation_date)
        let today: any = new Date()

        let timePassed = getDays(today.getTime() - installation_date.getTime())
        return (timePassed > 30 * 12 * 2)
    }

    function isAlmostDeprecated(device: any) {
        let installation_date: any = new Date(device.installation_date)
        let today: any = new Date()

        let timePassed = getDays(today.getTime() - installation_date.getTime())
        return (timePassed > 30 * 12 * 1.5) && (timePassed < 30 * 12 * 2)
    }

    function renderLeftContainer() {
        return (
            <ScrollView className='ControlContainer' style={{ width: '28%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' }}>
                <div style={{ width: '100%', height: '15%', display: 'flex', justifyContent: 'space-between' }}>
                    <div
                        className="carIconContainer"
                        onClick={() => navigate('/Etilometro/Control')}
                    >
                        <FontAwesomeIcon icon={faTruck} className="carIcon" />
                    </div>

                    <div
                        className="carIconContainer"
                        style={{ backgroundColor: 'gray' }}
                    >
                        <FontAwesomeIcon icon={faMobileAlt} className="carIcon" />
                    </div>
                </div>

                <div style={{ padding: '1rem' }} />

                <div style={{ width: '100%' }}>
                    <CustomDropBox
                        options={plates}
                        value={selectedPlate}
                        setValue={setSelectedPlate}
                        placeholder='Placa do Veículo'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ width: '100%' }}>
                    <CustomDropBox
                        options={companies}
                        value={selectedCompany}
                        setValue={setSelectedCompany}
                        placeholder='Empresa'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ color: 'white', alignSelf: 'flex-start', fontSize: 'calc(5px + .5rem)', fontWeight: 600 }}>
                    Vencimento do Sensor
                </div>

                <div style={{ padding: '.3rem' }} />

                <div
                    style={{ display: 'flex', width: '100%', cursor: 'pointer' }}
                    onClick={() => setDeprecatedSelected(!deprecatedSelected)}
                >
                    <div
                        className="buttonTravel"
                        style={{ backgroundColor: deprecatedSelected ? 'white' : 'gray' }}
                    >
                        Vencidos
                    </div>
                </div>

                <div style={{ padding: '.3rem' }} />

                <div
                    style={{ display: 'flex', width: '100%', cursor: 'pointer' }}
                    onClick={() => setAlmostDeprecatedSelected(!almostDeprecatedSelected)}
                >
                    <div
                        className="buttonTravel"
                        style={{ backgroundColor: almostDeprecatedSelected ? 'white' : 'gray' }}
                    >
                        Vencimento Próximo
                    </div>
                </div>

                <div style={{ padding: '1rem' }} />

                <button className='buttonInfoContainer' onClick={async () => await updateGraphs()} style={{ width: '50%' }}>
                    <FontAwesomeIcon icon={faSearch} style={{ paddingRight: '7%' }} />
                    <p style={{ fontSize: 'calc(5px + .5vw)' }}>Consultar</p>
                </button>
            </ScrollView>
        )
    }

    function renderPieLabel(label: string, color: string) {
        return (
            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: color }} />

                <div style={{ width: '85%', fontSize: 'calc(5px + .5vh)', color: 'white' }}>
                    {label}
                </div>
            </div>
        )
    }

    function renderRightContainer() {
        return (
            <div className='ControlContainer' style={{ width: '60%', justifyContent: 'space-between', padding: 0 }}>
                <div style={{ display: 'flex', width: '100%', height: '15%', justifyContent: 'space-between' }}>
                    <LoadingDiv loading={update} className='controlItem'>
                        <span>
                            Total de Sensores
                        </span>
                        <br /><br />
                        <span>
                            {targetSensors.length}
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem'>
                        <span>
                            Próximos a vencer
                        </span>
                        <br /><br />
                        <span>
                            {almostDeprecatedDevices.length}
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem'>
                        <span>
                            Vencidos
                        </span>
                        <br /><br />
                        <span>
                            {deprecatedDevices.length}
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem'>
                        <span>
                            Último Sincronismo
                        </span>
                        <br />
                        <span>
                            {webData.last_sync ? formatTimestamp(webData.last_sync) : 'N/A'}
                        </span>
                    </LoadingDiv>
                </div>

                <div style={{ display: 'flex', width: '100%', height: '45%', justifyContent: 'space-between' }}>
                    <LoadingDiv loading={update} className='controlItem' style={{ width: '60%', height: '100%' }}>
                        <div style={{ color: 'white' }}>
                            Etilômetros instalados
                        </div>

                        <div style={{ padding: '5px' }} />

                        <div style={{ width: '100%', flex: 1 }}>
                            <RenderMap data={webData.installed_devices_graph} />
                        </div>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '38%'}}>
                        <div style={{ color: 'white' }}>
                            Resultados de Testes
                        </div>

                        <div style={{ padding: '2px' }} />
                        
                        <CustomPieChart data={[
                            { 
                                name: 'Bloqueado (álcool)',
                                value: webData.tests_results_graph.blocked,
                                color: '#ff6961'
                            },
                            {
                                name: 'Liberado', 
                                value: webData.tests_results_graph.allowed, 
                                color: '#00ff58' 
                            },
                            {
                                name: 'Sem Sopro', 
                                value: webData.tests_results_graph.no_blow, 
                                color: '#004819'
                            },
                            { 
                                name: 'Adiado', 
                                value: webData.tests_results_graph.postponed, 
                                color: 'blue'
                            },
                        ]}
                        />

                        <div style={{ width: '100%', color: 'gray', justifyContent: 'space-between', display: 'flex' }}>
                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Bloqueado (álcool)', '#ff6961')}
                            </div>

                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Liberado', '#00ff58')}
                            </div>

                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Sem Sopro', '#004819')}
                            </div>

                            <div style={{ width: '31%' }}>
                                {renderPieLabel('Adiado', 'blue')}
                            </div>
                        </div>

                        <div style={{ padding: '2px' }} />
                    </LoadingDiv>
                </div>

                <div style={{ display: 'flex', width: '100%', height: '35%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '60%', height: '100%', justifyContent: 'space-between' }}>
                        <LoadingDiv loading={update} className='controlItem' style={{ width: '100%', height: '100%' }}>
                            <div style={{ color: 'white' }}>
                                Sensores Instalados
                            </div>

                            <div style={{ padding: '5px' }} />

                            <Table>
                                <TableRow>
                                    <TableCell>ID do Sensor</TableCell>
                                    <TableCell>Placa do Veículo</TableCell>
                                    <TableCell>Data de Registro</TableCell>
                                </TableRow>

                                {targetSensors.map((item: any) => {
                                    const deprecated    = deprecatedDevices.filter((device: any) => device.device.esp_id == item.device.esp_id)
                                    const is_deprecated = (deprecated.length > 0)

                                    const onClick = () => {
                                        saveJsonData('infoLog', item)
                                        navigate('/Etilometro/Info')
                                    }

                                    return (
                                        <TableRow onClick={onClick}>
                                            <TableCell color={is_deprecated ? 'red' : 'white'}>{item?.device?.sensor_id}</TableCell>
                                            <TableCell color={is_deprecated ? 'red' : 'white'}>{item?.vehicle_plate}</TableCell>
                                            <TableCell color={is_deprecated ? 'red' : 'white'}>{formatTimestamp(item.installation_date)} min</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Table>
                        </LoadingDiv>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="MainContainer">
            <TopBar />
            <div style={{ padding: '1rem' }} />

            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                {renderLeftContainer()}

                <div style={{ padding: '1.5%' }} />

                {renderRightContainer()}
            </div>

        </div>
    )
}