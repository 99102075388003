import { v4 as uuidv4 } from 'uuid'

export const DEBUG = false
export const url   = DEBUG ? 'http://localhost:8000/' : 'https://sighir.com:8000/'


export async function postWebData(urlRequest: string, data: any, timeout: number = 30000) {
	const controller = new AbortController()
	const id = setTimeout(() => controller.abort(), timeout)

	const options = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
		signal: controller.signal
	}
	
	try {
		const response = await fetch(urlRequest, options)
		clearTimeout(id)
		
		if (!response.ok) 
			return null
 
		return await response.json()
	}
	catch (error) {
		//alert(error)
		return null
	}
}

export async function fetchApiData(request: any) {
	try {
		const response = await fetch(url + request + '/');
		const data = await response.json();
		return data
	}
	catch (error) {
		console.log('Erro ao buscar dados:' + error)
		return {status: 'error', data: String(error)}
	}
}

export async function postApiData(data: any, request: string) {
	const response = await postWebData(url + request + '/', data)
	
	if(!response)
		return {status: 'error', data: 'error on post'}

	return response
}

export async function getDatabaseRows(tableName: string, maxRows: null|number=null) {
	let data: any  = {tableName: tableName}

	if(maxRows)
		data.limit = maxRows

	const response = await postApiData(data, 'api/getRows')

	if(!response)
		return []

	if(response.status != 'success')
		return []

	if(response.data.length == 0)
		return []

	return response.data
}

export async function getDataBaseCondition(tableName: string, columnName: string, desiredValue: string) {
	const data = {
		tableName: tableName,
		columnName: columnName,
		desiredValue: desiredValue,
	}

	const response = await postApiData(data, 'api/getCondition')

	if(!response || response.status != 'success')
		return []

	return response.data
}

export async function getDataBaseConditionMultiple(tableName: string, columnName1: string, desiredValue1: string, columnName2: string, desiredValue2: string) {
	const data = {
		tableName: tableName,
		columnName1: columnName1,
		value1: desiredValue1,
		columnName2: columnName2,
		value2: desiredValue2
	}
	
	const response = await postApiData(data, 'api/getDataMultiple')

	if(!response || response.status != 'success')
		return []

	return response.data
}

export async function editDatabaseByCondition(tableName: string, columnName: string, desiredValue: string, newData: any) {
	const data = {
		tableName: tableName,
		columnName: columnName,
		desiredValue: desiredValue,
		newData: newData
	}

	return await postApiData(data, 'api/editData')
}

export async function addDataBaseRow(tableName: string, newData: any) {
	const data = {
		tableName: tableName,
		newData: newData
	}
	
	return await postApiData(data, 'api/addData')
}

export async function editDatabaseRowsByColumn(tableName: string, columnName: string, value: string, newValue: string){
	const data = {
		tableName: tableName,
		columnName: columnName,
		value: value,
		newValue: newValue
	}

	return await postApiData(data, 'api/editRows')
}

export async function deleteDatabaseRow(tableName: string, columnName: string, value: string){
	const data = {
		key: '%%*$%%*$*$(*((%%*$*$(*($*$!%!$*$!%!', 
		tableName: tableName, 
		columnName: columnName,
		value: value
	}
	
	return await postApiData(data, 'api/deleteRow')
}

export async function getServerGraphs(data: any){
	try{
		const response = await postApiData(data, 'api/getGraphs')
		return response
	}
	catch(e){
		return null
	}
}

export function genUUID(size: number=30){
	const uuid = uuidv4()
	return uuid.substring(0, size)
}
