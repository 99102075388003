
import './styles.css'

export function Certifications() {
    return (
        <div id='container'>
            <div className='content-container'>

            </div>
        </div>
    )
}