import DownloadList from '../../../../components/DownloadList'
import TopBar from '../../../../components/TopBar'
import './styles.css'


export default function ProceduresPage(){
    const manuals = [
        {title: 'Procedimento Padrão de Montagem do Conjunto',   link: 'http://3.92.136.22:8000/montagem/'},
        {title: 'Procedimento Padrão Testes funcionais',         link: 'http://3.92.136.22:8000/func_tests_manual/'},
        {title: 'Procedimento Padrão Testes Operacionais',       link: 'http://3.92.136.22:8000/op_tests_manual/'},
        {title: 'Procedimento Padrão de Instalação',             link: 'http://3.92.136.22:8000/installation_manual/'},
        {title: 'Procedimento Padrão de Calibração',             link: 'http://3.92.136.22:8000/calibration_manual/'},
        {title: 'Procedimento Padrão Aplicativo Móvel',          link: 'http://3.92.136.22:8000/manual/'},
        {title: 'Procedimento de Troubleshooting',               link: 'http://3.92.136.22:8000/troubleshooting/'},
    ]

    return (
        <div className='MainContainer'>
            <TopBar/>

            <div style={{padding: '1rem'}}/>

            <DownloadList
                data={manuals}
                icon={'pdf'}
                title='Documentação - Procedimentos'
            />
        </div>
    )
}