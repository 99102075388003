import { useState } from "react";
import CustomDropBox from "../../../../components/CustomDropBox";
import TopBar from "../../../../components/TopBar";
import './styles.css';
import { getDataBaseCondition, getDatabaseRows, postApiData } from "../../../../utils/database";
import { sleep, useInitialCheck } from "../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFilePdf, faSearch } from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";
import LoadingDiv from "../../../../components/LoadingDiv";
import logo from '../../../../assets/img/logo/sighir_verde.png';
import LoadingCircle from "../../../../components/LoadingCircle";
import { formatTimestamp, getDeadline } from "../../../../components/DatePicker";
import ScrollView from "../../../../components/ScrollView";
import ass1 from '../../../../assets/img/ass/ass1.jpeg'
import ass2 from '../../../../assets/img/ass/ass2.jpeg'


const companies = [
    {label: 'Sighir'},
    {label: 'DAF'},
    {label: 'MIX'},
    {label: 'Logika'},
    {label: 'Predileto'},
]


export default function CalibrationPage(){
    const [vehicles, setVehicles] = useState<any>([])
    const [selectedVehicle, setSelectedVehicle] = useState<any>({})
    const [selectedCompany, setSelectedCompany] = useState<any>({})

    const [devices, setDevices] = useState<any>([])
    const [selectedDevice, setSelectedDevice] = useState<any>({})

    const [reports, setReports] = useState<any>([])
    const [targetReports, setTargetReports] = useState<any>([])

    const [update, setUpdate] = useState<boolean>(false)
    const [busy, setBusy] = useState<boolean|string>(false)

    useInitialCheck(importOptions)

    function findArrayItem(array: any, key: string, value: any){
        for(let x=0; x<array.length; x++)
            if(array[x][key] == value)
                return array[x]

        return null
    }

    async function importOptions(){
        setUpdate(true)
        
        let installedSensors = await getDatabaseRows('etilometers')
        let allCalibrated    = await getDatabaseRows('sensors')

        let uniqueByName: any = {}
        allCalibrated.forEach((item: any) => {uniqueByName[item.sensor_id] = item});
        allCalibrated = Object.values(uniqueByName);

        for(let x=0; x<allCalibrated.length; x++){
            const etilometer = allCalibrated[x]
            const item = findArrayItem(installedSensors, 'sensor_id', etilometer.sensor_id)

            if(!item)
                continue

            allCalibrated[x] = {...allCalibrated[x], ...item}
        }

        setVehicles(installedSensors.map((item: any) => ({label: item.vehicle_plate})))
        setDevices(installedSensors.map((item: any) => ({label: item.sensor_id})))
        
        setReports(allCalibrated)
        setTargetReports(allCalibrated)
        setUpdate(false)
    }


    async function handleDownloadItem(item: any){
        const espID = item.sensor_id
        const calibrationDate = item.timestamp

        const doc = new jsPDF('p', 'mm', 'a4');
        const width  = doc.internal.pageSize.getWidth() 
        const height = doc.internal.pageSize.getHeight() 
        
        const textWidth = width * .8
        const marginX   = width  * 0.1;
        const startY    = height * 0.05;
        let y = startY

        doc.addImage(logo, 'PNG', marginX, y, width*0.3, height*0.04);
        doc.setFontSize(16);
        
        y += .15*height
        doc.text('CERTIFICADO DE CALIBRAÇÃO', width*.5, y, { align: 'center' });

        const title1 = `Certifica-se que o sensor, modelo ${espID}, foi submetido a calibração conforme os procedimentos estabelecidos em ${formatTimestamp(calibrationDate)}. Durante o processo, o sensor foi verificado na faixa de 0,02 g/210L a 0,10 g/210L, empregando soluções certificadas*. A operação foi realizada sob condição de temperatura controlada de 34ºC ± 0,2ºC.`
        const title2 = 'Este documento confirma que o sensor atende aos padrões de confiabilidade de 97%, como especificado no datasheet correspondente.';
        const title3 = 'Validade do Certificado: Este certificado de calibração possui validade de dois anos a partir da data de sua emissão.';
        doc.setFontSize(12);

        y += .05*height
        doc.text(title1, marginX, y, { maxWidth: textWidth, align: 'justify' });

        y += .1*height
        doc.text(title2, marginX, y, { maxWidth: textWidth, align: 'justify' });
        
        y += .05*height
        doc.text(title3, marginX, y, { maxWidth: textWidth, align: 'justify' });

        y += .2*height
        doc.addImage(ass1, 'PNG', marginX,   y, width*0.3, height*0.05); 
        doc.addImage(ass2, 'PNG', width*0.6, y, width*0.3,   height*0.04); 
        
        y += .065*height
        doc.text('____________________________', marginX, y-6);
        doc.text('Técnico Responsável', marginX+10, y);

        doc.text('____________________________', width*0.6, y-6);
        doc.text('Supervisor', width*0.71, y);

        doc.setFontSize(10);
        doc.text('*As amostras padrões utilizadas de álcool são certificadas pela empresa Guth Laboratories Inc.', marginX, height*.95);

        doc.save("download.pdf");
    }

    function renderReport(item: any){
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div className="itemContainer">
                    <div className="columnItem">
                        <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={faFilePdf} className="iconItem"/>
                            <div style={{padding: '6px'}}/>
                            <div className="itemText">{item.sensor_id}</div>
                        </div>
                    </div>

                    <div className="columnItem">
                        <div className="itemText">{formatTimestamp(item.installation_date ? item.installation_date : item.timestamp)}</div>
                    </div>
                    
                    <div className="columnItem" >
                        {(busy == item.sensor_id) ? (<LoadingCircle/>) : (
                            <FontAwesomeIcon 
                                icon={faDownload} 
                                className={busy ? "downloadItemDisabled" : "downloadItem"} 
                                onClick={busy ? undefined : async () => {
                                    setBusy(true)
                                    await sleep(1000)
                                    await handleDownloadItem(item)
                                    await sleep(3000)
                                    setBusy(false)
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="line"/>
            </div>
        )
    }

    async function handleFilter(){
        let target = [...reports]

        if(selectedCompany.label)
            target = target.filter((item: any) => item.company == selectedCompany.label)

        if(selectedVehicle.label)
            target = target.filter((item: any) => item.vehicle_plate == selectedVehicle.label)

        if(selectedDevice.label)
            target = target.filter((item: any) => item.sensor_id == selectedDevice.label)

        setTargetReports(target)
    }

    return (
        <div className="MainContainer">
            <TopBar/>

            <div className='CentralBlock' style={{alignItems: 'center', justifyContent: 'center'}}>
                <div className="InstalationContainer">
                    <div style={{padding: '1rem'}}/>

                    <div style={{display: 'flex', width: '75%', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{width: '25%'}}>
                            <CustomDropBox
                                options={companies}
                                value={selectedCompany}
                                setValue={setSelectedCompany}
                                placeholder='Empresa'
                            />
                        </div>

                        <div style={{width: '25%'}}>
                            <CustomDropBox
                                options={vehicles}
                                value={selectedVehicle}
                                setValue={setSelectedVehicle}
                                placeholder='Veículo'
                            />
                        </div>

                        <div style={{width: '25%'}}>
                            <CustomDropBox
                                options={devices}
                                value={selectedDevice}
                                setValue={setSelectedDevice}
                                placeholder='Etilômetro'
                            />
                        </div>

                        <button 
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                height: '2.5rem',
                                backgroundColor: 'white', 
                                cursor: 'pointer', 
                                borderRadius: '.4rem'
                            }}
                            onClick={async () => await handleFilter()}
                        >
                            <FontAwesomeIcon icon={faSearch}/>
                            <div style={{padding: '5%'}}/>
                            <div>Filtrar</div>
                        </button>
                    </div>

                    <div style={{padding: '1rem'}}/>
                    
                    <ScrollView className="reports_new">
                        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                            <div className="columnItem">Nome</div>
                            <div className="columnItem">Data de Envio</div>
                            <div className="columnItem">Download</div>
                        </div>

                        <div className="line"/>

                        <LoadingDiv loading={update}>
                            {targetReports.map((item: any) => renderReport(item))}
                        </LoadingDiv>
                    </ScrollView>

                </div>
            </div>
        </div>
    )
}