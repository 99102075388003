import './styles.css'

type ImageProps = {
    img: any;
    size: number;
}

type SliderProps = {
    imgs: any[] | ImageProps[];
    imgContainerClass?: string;
}

export function Slider({ imgs, imgContainerClass }: SliderProps) {
    return (
        <div id="slider-container">
            <div className={"imgs-slide " + imgContainerClass}>
                { 
                    imgs[0].img === undefined ?
                    imgs.map(img => (
                        <div>
                            <img src={img}/>
                        </div>

                    )) :
                    imgs.map(imgObj => (
                        <div>
                            <img src={imgObj.img} height={imgObj.size}/>
                        </div>
                    ))
                }
            </div>

            <div className={"imgs-slide " + imgContainerClass}>
                { 
                    imgs[0].img === undefined ?
                    imgs.map(img => (
                        <div>
                            <img src={img}/>
                        </div>
 
                    )) :
                    imgs.map(imgObj => (
                        <div>
                            <img src={imgObj.img} height={imgObj.size}/>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}