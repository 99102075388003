import { faMobileAndroidAlt, faSearch, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import './styles.css'
import logo from '../../assets/img/logo/sighir_verde.png'
import { deleteJsonData, useInitialCheck } from '../../utils'
import SimpleDropDown from '../DropDown'

export default function TopBar() {
    const [searchText, setSearchText] = useState<string>('')
    useInitialCheck(handleTextChange, [searchText])
    const navigate = useNavigate()

    function handleDocSelection(pageName: string) {
        if (pageName == 'Anexos')
            return navigate('/Etilometro/Anexos')

        if (pageName == 'Manuais')
            return navigate('/Etilometro/Manuals')

        if (pageName == 'Datasheet')
            return navigate('/Etilometro/Datasheet')

        if (pageName == 'Videos de Instrução')
            return navigate('/Etilometro/Videos')

        navigate('/Etilometro/Procedures')
    }

    function handleReportSelection(pageName: string) {
        if (pageName == 'Instalação')
            return navigate('/Etilometro/Instalation')

        if (pageName == 'Calibração')
            return navigate('/Etilometro/Calibration')
    }

    function handleTextChange() {
        if (!searchText || searchText.length < 5)
            return

        if (searchText == 'Segurança e Privacidade')
            return navigate('/policy')

        if (searchText == 'Excluir Conta')
            return navigate('/delete-account')

        if (searchText == 'Dashboard')
            return navigate('/Etilometro/Control')

        if (searchText == 'Relatórios de Instalação')
            return navigate('/Etilometro/Instalation')

        if (searchText == 'Monitorar Logs')
            return navigate('/Etilometro/Home')

        if (searchText == 'Documentos Auxiliares')
            return navigate('/Etilometro/Procedures')

        if (searchText == 'Atualização de Software')
            return navigate('/Etilometro/Software')
    }

    return (
        <div className='topBarContainer'>
            <div id='topBarContentContainer'>
                <div
                    onClick={() => navigate('/')}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '10%',
                        maxWidth: 150,
                        minWidth: 60,
                        height: '100%',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        userSelect: 'none'
                    }}
                >
                    {/*<FontAwesomeIcon icon={faTh} color='gray' style={{fontSize: '2rem'}}/>*/}
                    <div style={{ padding: '5px' }} />
                    <img src={logo} alt='logo' width='90%' />
                </div>
                
                <section>

                    <div className='topBarTextContainer'>
                        <div className='topBarText' onClick={() => navigate('/Etilometro/Home')}>
                            Monitoramento
                        </div>
                    </div>

                    <div className='topBarTextContainer'>
                        <div className='topBarText' onClick={() => navigate('/Etilometro/Software')}>
                            Software
                        </div>
                    </div>

                    <div className='topBarTextContainer'>
                        <div className='topBarText' onClick={() => navigate('/Etilometro/Control')}>
                            Controle
                        </div>
                    </div>

                    <SimpleDropDown
                        options={['Calibração', 'Instalação', 'Testes']}
                        selected={'Relatórios'}
                        setSelected={handleReportSelection}
                        textClass={'topBarText'}
                        className={'topBarTextContainer'}
                        itemClass={'topBarTextItem'}
                        textColor={'rgb(36,156,92)'}
                        placeholder={'Relatórios'}
                        bgColor='#383638'
                        icon={false}
                        iconStyle={false}
                    />

                    <SimpleDropDown
                        options={['Procedimentos', 'Manuais', 'Datasheet', 'Videos de Instrução', 'Anexos']}
                        selected={'Documentação'}
                        setSelected={handleDocSelection}
                        textClass={'topBarText'}
                        className={'topBarTextContainer'}
                        itemClass={'topBarTextItem'}
                        textColor={'rgb(36,156,92)'}
                        placeholder={'Documentação'}
                        bgColor='#383638'
                        icon={false}
                        iconStyle={false}
                        style={{ marginLeft: '-2.5%' }}
                    />
                </section>

                <div className='topBarInputContainer'>
                    <div style={{ padding: '5px' }} />
                    <FontAwesomeIcon icon={faSearch} size='xl' />

                    <div style={{ padding: '5px' }} />

                    <input
                        list="options"
                        className='topBarInput'
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                    />

                    <datalist id="options">
                        <option value="Segurança e Privacidade" />
                        <option value="Excluir Conta" />
                        <option value="Dashboard" />
                        <option value="Relatórios de Instalação" />
                        <option value="Monitorar Logs" />
                        <option value="Documentos Auxiliares" />
                        <option value="Atualização de Software" />
                    </datalist>
                </div>

                <div>
                    <FontAwesomeIcon
                        onClick={() => navigate('/Etilometro/AppInfo')}
                        icon={faMobileAndroidAlt} color='gray'
                        style={{ paddingRight: '15px', fontSize: '1.5rem', cursor: 'pointer' }}
                        title='Aplicativo Mobile'
                    />

                    <FontAwesomeIcon
                        icon={faSignOut}
                        color='gray'
                        style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                        onClick={() => { 
                            deleteJsonData('user')
                            navigate('/Etilometro/')
                            localStorage.clear()
                        }}
                        title='log out'
                    />
                </div>
                <div />

            </div>
        </div>
    )
}

