import DownloadList from '../../../../components/DownloadList'
import TopBar from '../../../../components/TopBar'
import './styles.css'


export default function DatasheetPage(){
    const manuals = [
        {title: 'Detector de álcool SIGHIR v4.0.1', link: 'http://3.92.136.22:8000/datasheet/'},
    ]

    return (
        <div className='MainContainer'>
            <TopBar/>

            <div style={{padding: '1rem'}}/>

            <DownloadList
                data={manuals}
                icon={'pdf'}
                title='Documentação - Datasheets'
            />
        </div>
    )
}