import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
// FORMAT OF DATE: 2021-01-18 22:40:50

export function isValidDate(dateString: string){
    return !isNaN(new Date(dateString).getTime())
}

export function isTimestampBetween(dateString: string, inicialDateString: string, finalDateString: string){
    const date = new Date(dateString)
    const initialDate = new Date(inicialDateString)
    const finalDate = new Date(finalDateString)

    if(isValidDate(inicialDateString) && !isValidDate(finalDateString))
        return (date >= initialDate)

    if(!isValidDate(inicialDateString) && isValidDate(finalDateString))
        return (date <= finalDate)
    
    if(isValidDate(inicialDateString) && isValidDate(finalDateString))
        return (date >= initialDate) && (date <= finalDate)

    return false
}

export function formatTimestamp(datetime: string|undefined, fuso=true){
    if (!datetime)
      return 'None';
  
    let date = new Date(datetime);
  
    if(fuso){
      const brazilOffset = -3 * 60 * 60000;           // Offset de Brasília (UTC-3) em milissegundos
      date = new Date(date.getTime() + brazilOffset) // Convertendo para o horário de Brasília
    }
    
    return date.toLocaleString('pt-BR')
}

export function getDeadline(date: any){
    if(typeof(date) == 'string')
      date = new Date(date)
    
    const year    = date.getFullYear();
    const month   = (date.getMonth() + 1).toString().padStart(2, '0');
    const day     = date.getDate().toString().padStart(2, '0');
    const hours   = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
  
export function sortArrayDatetime(array: any, key: string){
    return array.sort((a: any, b: any) => {
        let dateA: any = new Date(a[key]);
        let dateB: any = new Date(b[key]);
        return dateB - dateA;
    })
}


export function DateSelector({ value, onChange, placeholder='Prazo' }: any){
    function handleChange(date: any){
        if(!date)
            date = getDate('now')

        const formatted: string = formatDateTimeForValue(date) // Função para formatar a data
        onChange(formatted)
    }

    function getDate(value: string){
        if(value.length == 0)
            return null

        const date = (value === 'now') ? new Date() : new Date(value)
        
        if(value === 'now')
            handleChange(date)

        return date
    }

    function formatDateTimeForValue(date: Date): string {
        // Formata a data e hora no formato desejado
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <div className="DatePickerContainer">
            <DatePicker
                dateFormat="dd/MM/yyyy HH:mm:ss" // Atualizado para o formato desejado
                placeholderText={placeholder}
                selected={getDate(value)}
                onChange={(value) => handleChange(value)}
                className='DatePickerStyle'
                showYearDropdown
                showTimeSelect
                timeFormat="HH:mm:ss" // Atualizado para incluir segundos
                timeIntervals={15} // Intervalos de 15 minutos
            />

            <FontAwesomeIcon icon={faCalendar} color="white"/>
        </div>
    );
};
