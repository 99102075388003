import './App.css';
import AppRoutes from './routes';



export default function App() {
	return (
		<div>
			<AppRoutes />
		</div>
	)
}