import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import './styles.css'

import googlePlayLogo from '../../../../assets/img/google-play.svg'
import appleStoreLogo from '../../../../assets/img/apple-store.svg'

export function Footer() {
    const faPropInstagram = faInstagram as IconProp
    const faPropLinkedin = faLinkedin as IconProp

    return (
        <footer>
            <div id='footer-content'>
                <section id='footer-info'>
                    <div id='footer-company-field'>
                        <p>Empresa</p>
                        <Link to='/about'>Quem somos</Link>
                        <Link to='/policy'>Política de Privacidade</Link>
                        <span>Certificações</span>
                        <span>Notícias</span>
                    </div>

                    <div>
                        <p>Instale nosso app</p>

                        <a>
                            <img src={googlePlayLogo} />
                        </a>

                        <a>
                            <img src={appleStoreLogo} />
                        </a>
                    </div>

                    <div>
                        <p>Siga-nos</p>

                        <div>
                            <a
                                href='https://www.instagram.com/sighir.oficial?igsh=MWdlNDBldnMxcHpoaA=='
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faPropInstagram}
                                    color='white'
                                    size='4x'
                                    className='footer-icon'
                                />
                            </a>
                            <a
                                href='https://br.linkedin.com/company/sighir-enterprise'
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faPropLinkedin}
                                    color='white'
                                    size='4x'
                                    className='footer-icon'

                                />
                            </a>
                        </div>
                    </div>
                </section>

                <section id='footer-company-info'>
                    <div>
                        <p>© Sighir Enterprise LTDA • 2024</p>
                        <p>
                            Estrada velha do Pilar, 1675 - Chácara Rio-Petrópolis, Duque de Caxias
                            Rio de Janeiro - RJ •  25230-610 • 44.922.499/0001-60
                        </p>
                    </div>
                </section>
            </div>
        </footer>
    )
}