import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useInitialCheck } from '../../utils';

export default function CustomTextField({ value, setValue, placeholder, onKeyDown = null, height = '3.2rem', type = 'text' }: any) {
    const [text, setText] = useState<string>(value);

    const sxTextFieldOptions = {
        width: '100%',
        height: height,
        borderWidth: '15px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderRadius: '5px',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '.MuiInputBase-input': {
            fontSize: '12px', // Ajusta o tamanho da fonte do texto dentro do TextField
        },
        '.MuiInputLabel-outlined': {
            fontSize: '12px', // Ajusta o tamanho da fonte da label quando não está "shrinked"
            opacity: 0.5,
        },
        '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 0px) scale(0.8)', // Ajusta a posição e escala da label quando "shrinked"
        },
    };

    useEffect(() => {
        setText(value);
    }, [value]);

    const handleChange = (event: any) => {
        const newValue = event.target.value;
        setText(newValue);
        setValue(newValue);
    };

    return (
        <TextField
            label={placeholder}
            value={text}
            onChange={handleChange}
            sx={sxTextFieldOptions}
            variant="outlined"
            onKeyDown={onKeyDown}
            type={type}
        />
    );
}
