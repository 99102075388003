import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'
import { faDownload, faFile, faFileAlt, faFilePdf, faFileVideo, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import LoadingDiv from '../LoadingDiv'
import { useState } from 'react'
import { sleep } from '../../utils'
import { display } from 'html2canvas/dist/types/css/property-descriptors/display'
import ScrollView from '../ScrollView'

export default function DownloadList({data, icon='pdf', update=false, title=''}: any){
    const [busy, setBusy] = useState<boolean>(false)
    const isVideo = (icon == 'video')

    if(icon == 'pdf')
        icon = <FontAwesomeIcon className='iconItem' icon={faFilePdf}/>
    else if(icon == 'video')
        icon = <FontAwesomeIcon className='iconItem' icon={faFileVideo}/>
    else if(icon == 'file')
        icon = <FontAwesomeIcon className='iconItem' icon={faFileAlt}/>

    //alert(JSON.stringify(data))

    function renderItem(item: any){
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div className='itemContainer'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {icon}
                        <div style={{padding: '10px'}}/>
                        <div className='itemText'>{item.title}</div>
                    </div>

                    <FontAwesomeIcon 
                        icon={isVideo ? faPlayCircle : faDownload} 
                        className={busy ? 'downloadItemDisabled' : 'downloadItem'}
                        onClick={busy ? undefined : async () => {
                            setBusy(true)
                            await sleep(500)
                            window.open(item.link, '_blank');
                            await sleep(1500)
                            setBusy(false)
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <ScrollView className='DownloadListContainer'>
            <div style={{
                fontSize: '2rem', 
                fontWeight: 'bold', 
                color: 'white', 
                width: '60%', 
                alignSelf: 'center',
                textAlign: 'left'
            }}>
                {title}
            </div>

            <div style={{padding: '1.2rem'}}/>

            <LoadingDiv 
                loading={update} 
                style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center'}}
            >
                {
                    data.map((item: any) => renderItem(item))
                }
            </LoadingDiv>

            <div style={{height: '1rem'}}/>
        </ScrollView>   
    )
}