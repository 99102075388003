import './styles.css'

export default function AsyncButton({active=false, text='', className='', onClick, loading=false}){
    const buttonEnabled = {
        opacity: 1,
        cursor: 'pointer',
    }
    
    const buttonDisabled = {
        opacity: .7,
        cursor: 'not-allowed'
    }

    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        ...(active ? buttonDisabled : buttonEnabled),
    }
    
    if(!loading)
        return (
            <div 
                className={className} 
                style={buttonStyle}
                onClick={active ? () => null : onClick}
                >

                {text}
            </div>
        )

    return (
        <div 
            className={className} 
            style={buttonStyle}
            onClick={active ? () => null : onClick}
            >

            {active ? <div className='loadingCircle'/> : text}
        </div>
    )
}