import { useEffect } from "react";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo from '../assets/img/logoSIGHIR.png'

export type ItemType = {
	label: string;
	value: string;
}

export function getCookie(name: string) {
	let cookieValue = null;
	if (document.cookie && document.cookie !== '') {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			if (cookie.substring(0, name.length + 1) === (name + '=')) {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
}

export function useInitialCheck(checkFunction: any, params: any = []) {
	useEffect(() => {
		async function check() {
			await checkFunction();
		}

		check();
	}, params);
}

export function useInterval(callback: any, delay: number) {
	useEffect(() => {
		const intervalId = setInterval(callback, delay);

		return function cleanup() {
			clearInterval(intervalId);
		};
	}, [callback, delay]);
}

export async function sleep(ms: number) {
	await new Promise(resolve => setTimeout(resolve, ms));
}

export function saveJsonData(key: string, data: any) {
	try {
		const jsonData = JSON.stringify(data);
		localStorage.setItem(key, jsonData);
		return true
	}
	catch (error) {
		console.error("Error saving data to localStorage", error);
		return false
	}
}

export function getStoredJson(key: string, onEmpty: any = null) {
	try {
		const jsonData = localStorage.getItem(key);
		return jsonData ? JSON.parse(jsonData) : onEmpty;
	}
	catch (error) {
		console.error("Error retrieving data from localStorage", error);
		return onEmpty;
	}
}

export function deleteJsonData(key: string) {
	try {
		localStorage.removeItem(key);
		console.log(`Data with key '${key}' has been successfully removed from localStorage`);
		return true;
	} catch (error) {
		console.error("Error removing data from localStorage", error);
		return false;
	}
}

export async function handleExportExcel(rows: any) {
	const planilha = XLSX.utils.json_to_sheet(rows);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, planilha, "Dados");
	XLSX.writeFile(workbook, "Dados.xlsx"); // download
}

export async function handleExportPDF(rows: any) {
	const doc = new jsPDF();

	const width = 210 / 100
	const height = 297 / 100

	let x = width * 7
	let y = height * 1

	const addText = (text = '', yOffset = 7) => {
		doc.text(text, x, y);
		y += yOffset;
	}

	doc.addImage(logo, 'PNG', x * 0.95, y, width * 25, height * 5.5);
	y += 9 * height

	doc.setFontSize(15)
	doc.setFont('helvetica', 'bold')
	addText('Logs Filtrados')

	const startY = y;

	const tableColumn = Object.keys(rows[0]);
	const tableRows = rows.map((item: any) => Object.values(item));

	autoTable(doc, {
		startY,
		head: [tableColumn],
		body: tableRows,
		styles: {
			fontSize: 6
		}
	});

	doc.save('filtered_data.pdf');
}