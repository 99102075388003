import DownloadList from '../../../../components/DownloadList'
import TopBar from '../../../../components/TopBar'
import './styles.css'
import videoApp from '../../../../assets/video/video_app.mp4'
import video from '../../../../assets/video/video.mp4'



export default function ProceduresPage(){
    const manuals = [
        {title: 'Introdução ao Etilômetro', link: videoApp},
        {title: 'Inovação e Pesquisa', link: video},
    ]

    return (
        <div className='MainContainer'>
            <TopBar/>

            <div style={{padding: '1rem'}}/>

            <DownloadList
                data={manuals}
                icon={'video'}
                title='Vídeos de Instrução'
            />
        </div>
    )
}